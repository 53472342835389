import { request, noTimeOutReq } from '@/utils/request.js'

export function getMediaServerConfigApi () {
  return request({
    url: `/server/media/get/config`,
    method: 'post',
  })
}

export function getMediaServerStreamListApi () {
  return request({
    url: `/server/media/list/stream`,
    method: 'post',
  })
}

export function forceCloseStreamApi (data) {
  return request({
    url: `/server/media/close/stream`,
    method: 'post',
    data
  })
}

export function addStreamProxyApi (data) {
  return request({
    url: `/server/media/add/stream`,
    method: 'post',
    data
  })
}

export function isRecordingApi (data) {
  return request({
    url: `/server/media/isRecording`,
    method: 'post',
    data
  })
}

export function getSnapApi (data) {
  return request({
    url: `/server/media/snap`,
    method: 'post',
    data
  })
}

export function getAllSessionApi () {
  return request({
    url: `/server/media/all/session`,
    method: 'post',
  })
}

export function addStreamPusherProxyApi (data) {
  return request({
    url: `/server/media/add/stream/pusher/proxy`,
    method: 'post',
    data
  })
}