<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="服务配置" name="1">
      <div style="padding-bottom: 10px">
        <el-button type="success" @click="getMediaServerConfig('refresh')"
          >刷新服务配置</el-button
        >
      </div>
      <div>
        <el-table :data="serverConfig" border height="1000">
          <el-table-column prop="key" label="配置"></el-table-column>
          <el-table-column prop="value" label="配置值"></el-table-column>
        </el-table>
      </div>
    </el-tab-pane>
    <el-tab-pane label="流管理" name="2">
      <div style="padding-bottom: 10px">
        <el-button type="success" @click="getMediaServerStreamList('refresh')"
          >刷新</el-button
        >
        <el-button type="primary" @click="addStreamDialogHandleOpen()"
          >添加流</el-button
        >
      </div>
      <div>
        <el-row>
          <el-table
            stripe
            border
            ref="multipleTable"
            :data="streamList"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              background: '#DFE6EC',
            }"
          >
            <el-table-column
              prop="app"
              label="应用名"
              min-width="15"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="stream"
              label="流id"
              min-width="20"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="vhost"
              label="虚拟主机"
              min-width="30"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="schema"
              label="协议类型"
              min-width="20"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="originUrl"
              label="摄像头流地址"
              min-width="80"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              label="代理流地址"
              min-width="80"
              show-overflow-tooltip
              align="center"
            >
              <template slot-scope="scope">
                <span>
                  {{ $mediaHttpAddress(scope.row.proxyStreamUrl) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="aliveSecond"
              label="存活时间(秒)"
              min-width="20"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column label="操作" min-width="45" align="center">
              <template slot-scope="scope">
                <el-button
                  v-throttle
                  type="primary"
                  size="mini"
                  @click="addStreamPusherProxyDialogHandleOpen(scope.row)"
                  >推流
                </el-button>
                <el-button
                  v-throttle
                  type="primary"
                  size="mini"
                  @click="
                    watchStream(
                      scope.row.proxyStreamUrl,
                      scope.row.app,
                      scope.row.stream,
                      scope.row.vhost
                    )
                  "
                  >观看
                </el-button>
                <el-button
                  v-throttle
                  type="danger"
                  size="mini"
                  @click="
                    forceCloseStream(
                      scope.row.app,
                      scope.row.stream,
                      scope.row.vhost
                    )
                  "
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </div>
    </el-tab-pane>
    <el-tab-pane label="客户端列表" name="3">
      <div>
        <el-table
          stripe
          border
          ref="sessionInfoTable"
          :data="sessionInfoList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            background: '#DFE6EC',
          }"
        >
          <el-table-column
            prop="tcpId"
            label="tcp链接id"
            min-width="30"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="localIp"
            label="本机网卡ip"
            min-width="30"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="localPort"
            label="本机端口号"
            min-width="30"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="peerIp"
            label="客户端ip"
            min-width="30"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="peerPort"
            label="客户端端口号"
            min-width="30"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="typeId"
            label="Session typeId"
            min-width="30"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
    </el-tab-pane>
    <el-dialog
      :visible.sync="watchStreamDialog"
      :before-close="watchStreamDialogHandleClose"
    >
      <div class="watchStreamDialog">
        <flv-com ref="flvRef" />
      </div>
      <div class="recording-button">
        <el-button v-throttle type="primary" @click="getSnap()">
          截图
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="添加流"
      :visible.sync="addStreamDialog"
      width="30%"
      :before-close="addStreamDialogHandleClose"
    >
      <div style="padding-bottom: 10px; padding-left: 100px; color: #ff0000">
        <span>此功能用于调试,流地址应该绑定于考场,供考试巡查使用!</span>
      </div>
      <el-form
        :model="addStreamForm"
        status-icon
        :rules="addStreamRules"
        ref="addStreamFormRef"
        label-width="100px"
      >
        <el-form-item label="应用名" prop="app">
          <el-input v-model="addStreamForm.app"></el-input>
        </el-form-item>
        <el-form-item label="流id" prop="stream">
          <el-input v-model="addStreamForm.stream"></el-input>
        </el-form-item>
        <el-form-item label="摄像头流地址" prop="originalStreamUrl">
          <el-input v-model="addStreamForm.originalStreamUrl"></el-input>
        </el-form-item>
        <el-form-item label="虚拟主机" prop="vhost">
          <el-input v-model="addStreamForm.vhost" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitAddStreamForm()"
            >提交</el-button
          >
          <el-button @click="resetAddStreamForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="推流"
      :visible.sync="addStreamPusherProxyDialog"
      width="30%"
      :before-close="addStreamDialogHandleClose"
    >
      <el-form
        :model="addStreamPusherProxyForm"
        status-icon
        :rules="addStreamPusherProxyRules"
        ref="addStreamPusherProxyFormRef"
        label-width="100px"
      >
        <el-form-item label="应用名" prop="app">
          <el-input v-model="addStreamPusherProxyForm.app" disabled></el-input>
        </el-form-item>
        <el-form-item label="流id" prop="stream">
          <el-input
            v-model="addStreamPusherProxyForm.stream"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="虚拟主机" prop="vhost">
          <el-input
            v-model="addStreamPusherProxyForm.vhost"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="协议类型">
          <el-radio v-model="schema" label="1">RTSP</el-radio>
          <el-radio v-model="schema" label="2">RTMP</el-radio>
        </el-form-item>
        <el-form-item label="目标推流地址" prop="dstUrl">
          <el-input v-model="addStreamPusherProxyForm.dstUrl"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitAddStreamPusherProxyForm()"
            >提交</el-button
          >
          <el-button @click="resetAddStreamPusherProxyForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-tabs>
</template>
<script>
import mpegtsCom from '@/components/mpegtsVideoCom.vue'
import {
  getMediaServerStreamListApi,
  getMediaServerConfigApi,
  forceCloseStreamApi,
  addStreamProxyApi,
  isRecordingApi,
  getSnapApi,
  getAllSessionApi,
  addStreamPusherProxyApi,
} from "@/api/system/mediaManage.js";
import { isEmpty } from "@/utils/util";
export default {
  name: "mediaManage",
  components: { flvCom:mpegtsCom },
  data() {
    var checkApp = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入应用名"));
      } else {
        callback();
      }
    };
    var checkStream = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入流id"));
      } else {
        callback();
      }
    };
    var checkOriginalStreamUrl = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入原始流地址"));
      } else {
        callback();
      }
    };
    var checkDstUrl = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入推流目标地址"));
      } else {
        callback();
      }
    };
    return {
      activeName: "1",
      serverConfig: [],
      streamList: [],
      watchStreamDialog: false,
      addStreamDialog: false,
      addStreamPusherProxyDialog: false,
      recordingStatus: false,
      proxyStreamUrl: "",
      schema: "2",
      addStreamForm: {
        app: "",
        stream: "",
        vhost: "__defaultVhost__",
        originalStreamUrl: "",
      },
      addStreamPusherProxyForm:{
        app: "",
        stream: "",
        vhost: "__defaultVhost__",
        dstUrl: "",
        schema: "",
      },
      addStreamRules: {
        app: [{ validator: checkApp, trigger: "blur" }],
        stream: [{ validator: checkStream, trigger: "blur" }],
        originalStreamUrl: [
          { validator: checkOriginalStreamUrl, trigger: "blur" },
        ],
      },
      addStreamPusherProxyRules:{
        dstUrl: [
          { validator: checkDstUrl, trigger: "blur" },
        ],
      },
      sessionInfoList: [],
    };
  },
  mounted() {
    this.init();
  },
  filters: {
    //时间过滤
    dateFormat(val) {
      const dt = new Date(val);
      const y = dt.getFullYear();
      const m = (dt.getMonth() + 1 + "").padStart(2, "0");
      const d = (dt.getDate() + "").padStart(2, "0");
      //padStart(2,'0') 意思是不足两位自动补0
      const hh = (dt.getHours() + "").padStart(2, "0");
      const mm = (dt.getMinutes() + "").padStart(2, "0");
      const ss = (dt.getSeconds() + "").padStart(2, "0");
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
    },
  },
  methods: {
    init() {
      this.getMediaServerConfig();
    },
    refresh() {
      this.init();
    },
    handleClick(tab, event) {
      console.log("tab index", tab.index)
      console.log(tab.index);
      switch (tab.index) {
        case "0":
          this.getMediaServerConfig();
          break;
        case "1":
          this.getMediaServerStreamList();
          break;
        case "2":
          this.getAllSession();
          break;
      }
    },
    watchStreamDialogHandleClose(done) {
      done();
      this.$refs.flvRef.destroyPlayer();
    },
    addStreamDialogHandleOpen() {
      this.addStreamDialog = true;
    },
    addStreamDialogHandleClose(done) {
      done();
    },
    addStreamPusherProxyDialogHandleOpen(row) {
      this.addStreamPusherProxyForm.app = row.app
      this.addStreamPusherProxyForm.stream = row.stream
      this.addStreamPusherProxyForm.vhost = row.vhost
      this.addStreamPusherProxyDialog = true;
    },
    addStreamPusherProxyDialogHandleClose(done) {
      done();
    },
    submitAddStreamForm() {
      this.$refs.addStreamFormRef.validate((valid) => {
        if (valid) {
          this.addStreamProxy()
        }
      });
    },
    submitAddStreamPusherProxyForm(){
      this.$refs.addStreamPusherProxyFormRef.validate((valid) => {
        if(valid){
          this.addStreamPusherProxy()
        }
      })
    },
    resetAddStreamForm() {
      this.$refs.addStreamFormRef.resetFields();
    },
    resetAddStreamPusherProxyForm(){
      this.$refs.addStreamPusherProxyFormRef.resetFields();
    },
    getMediaServerConfig(type) {
      getMediaServerConfigApi().then((res) => {
        if (res.success) {
          this.serverConfig = this.serverConfig = this.convertObjectToArray(
            res.data
          );
          if (type == "refresh") {
            this.msgSuccess("刷新成功");
          }
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg);
          }
        }
      });
    },
    getMediaServerStreamList(type) {
      getMediaServerStreamListApi().then((res) => {
        if (res.success) {
          this.streamList = [];
          this.streamList = res.data;
          if (type == "refresh") {
            this.msgSuccess("刷新成功");
          }
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg);
          }
        }
      });
    },
    addStreamProxy() {
      let param = {
        app: this.addStreamForm.app,
        stream: this.addStreamForm.stream,
        vhost: this.addStreamForm.vhost,
        url: this.addStreamForm.originalStreamUrl,
      };
      addStreamProxyApi(param).then((res) => {
        if (res.success) {
          this.msgSuccess("添加成功");
          this.addStreamForm = {
            app: "",
            stream: "",
            vhost: "__defaultVhost__",
            originalStreamUrl: "",
          };
          this.addStreamDialog = false;
          setTimeout(() => {
            this.getMediaServerStreamList();
          }, 350);
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg);
          }
        }
      });
    },
    addStreamPusherProxy(){
      let param = {
        app: this.addStreamPusherProxyForm.app,
        stream: this.addStreamPusherProxyForm.stream,
        vhost: this.addStreamPusherProxyForm.vhost,
        schema: this.schema,
        dstUrl: this.addStreamPusherProxyForm.dstUrl,
      };
      console.log("推流参数：", param);
      addStreamPusherProxyApi(param).then((res) => {
        if (res.success) {
          this.msgSuccess("推流成功！");
          this.addStreamPusherProxyForm = {
            app: "",
            stream: "",
            vhost: "__defaultVhost__",
            dstUrl: "",
            schema: "",
          };
          this.addStreamPusherProxyDialog = false;
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg);
          }
        }
      });
    },
    forceCloseStream(app, stream, vhost) {
      let param = {
        app: app,
        stream: stream,
        vhost: vhost,
      };
      forceCloseStreamApi(param).then((res) => {
        if (res.success) {
          this.msgSuccess("关闭成功");
          this.getMediaServerStreamList();
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg);
          }
        }
      });
    },
    watchStream(proxyStreamUrl, app, stream, vhost) {
      this.proxyStreamUrl = "";
      this.proxyStreamUrl = this.$mediaHttpAddress(proxyStreamUrl);
      this.watchStreamDialog = true;
      this.$nextTick(() => {
        this.$refs.flvRef.url = this.$mediaHttpAddress(proxyStreamUrl);
        this.$refs.flvRef.initPlayer();
      });
      this.isRecording(app, stream, vhost);
    },
    isRecording(app, stream, vhost) {
      let param = {
        app: app,
        stream: stream,
        vhost: vhost,
      };
      isRecordingApi(param).then((res) => {
        if (res.success) {
          console.log("流录制状态:", res.data);
          if (res.data == true) {
            this.recordingStatus = true;
          }
          if (res.data == false) {
            this.recordingStatus = false;
          }
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg);
          }
        }
      });
    },
    getSnap() {
      let param = {
        proxyStreamUrl: this.proxyStreamUrl,
      };
      getSnapApi(param).then((res) => {
        if (res.success) {
          window.open(this.$imageAddress(res.data));
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg);
          }
        }
      });
    },
    getAllSession(){
      getAllSessionApi().then((res) => {
        if (res.success) {
          this.sessionInfoList = res.data
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg);
          }
        }
      })
    },
    convertObjectToArray(object) {
      return Object.keys(object).map((key) => {
        let value =
          object[key] === "" || object[key] === null ? "空" : object[key];
        return {
          key: key,
          value: value,
        };
      });
    },
  },
  watch: {},
};
</script>
<style>
.watchStreamDialog {
  position: relative;
  width: 100%;
  height: 80%;
  margin: 0;
  padding: 0;
  padding-bottom: 56.25%;
}
.recording-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
</style>
